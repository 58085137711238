
























































import { Component, Vue, Watch } from "vue-property-decorator";

import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscCard from "@/components/Card/FscCard.vue";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import TabMixin from "@/mixins/TabMixin";
import CompanyMixin from "@/mixins/CompanyMixin";

const DrivingSchool = namespace("driving-school");

@Component({
  components: { FscSimpleCard, AbortButton, SaveButton, FscCard },
})
export default class Switches extends mixins(TabMixin, CompanyMixin) {
  public name = "Switches";

  @DrivingSchool.Action("update")
  public update: any;

  @DrivingSchool.Getter("getIsLoading")
  public isLoading: any;

  @DrivingSchool.Getter("getDrivingSchoolSwitches")
  public getDrivingSchoolSwitches: any;

  @DrivingSchool.Action("findDrivingSchoolSwitches")
  public findDrivingSchoolSwitches: any;

  public drivingSchoolSwitches: any = [];

  public async mounted(): Promise<void> {
    await this.findDrivingSchoolSwitches();
    this.drivingSchoolSwitches = this.getDrivingSchoolSwitches;
    this.drivingSchoolSwitches.licenseClasses.map((lcl: any, index: number) => {
      return (lcl.originalIndex = index);
    });
  }

  public onAbort() {
    this.closeCurrentTab();
  }

  public async onSubmit(): Promise<void> {
    if (this.hasNotPermissionWrite) return;

    const data = this.drivingSchoolSwitches;

    const id = "current/switches";

    await this.update({
      id: id,
      data: data,
      resource: "driving-schools",
    });
  }
}
